import "./talks.css"

import React from "react"
import Layout from "../components/layout"
import { YoutubeVideo } from "../components/youtube"

const TalkContent = ({ title, children, date, at }) => {
  return (
    <div className="wsscode-talk-content">
      <h2>{title}</h2>
      {children}
      <div className="wsscode-talk-meta">{date} @ {at}</div>
    </div>
  )
}

const Talk = ({ children }) => {
  return (
    <div className="wsscode-talk">
      {children}
    </div>
  )
}

const Talks = ({ location }) => {
  return (
    <Layout location={location} isWide={true}>
      <Talk>
        <YoutubeVideo videoId="YaHiff2vZ_o" />
        <TalkContent title="Data Navigation with Pathom 3" date="October 2021" at="London Clojurians">
          Understand the problem of "container types", which dominate most of the information
          systems on the internet, and how we can use attribute modeling avoid propagating
          these patterns.
        </TalkContent>
      </Talk>

      <Talk>
        <YoutubeVideo videoId="R_XPwi0Kiwk" />
        <TalkContent title="Evolving Code Through Accretion" date="June 2020" at="Clojure North">
          Some tips to design code without breaking things.
        </TalkContent>
      </Talk>

      <Talk>
        <YoutubeVideo videoId="IS3i3DTUnAI" />
        <TalkContent title="The Maximal Graph" date="November 2019" at="Clojure Conj">
          In this talk I go though my vision about how we should model information and
          make it connected.
        </TalkContent>
      </Talk>

      <Talk>
        <YoutubeVideo videoId="yyVKf2U8YVg" />
        <TalkContent title="Scaling Full-Stack Applications" date="November 2018" at="Clojure Conj">
          In this talk I go over how we tamed a complex distributed data at Nubank to
          serve customer information during support.
        </TalkContent>
      </Talk>

      <Talk>
        <YoutubeVideo videoId="r3zywlNflJI" />
        <TalkContent title="Implementing Graph API's with Clojure" date="April 2018" at="Clojure Days">
          In this talk I go over how to have something similar to GraphQL, but using
          Clojure constructs.
        </TalkContent>
      </Talk>
    </Layout>
  )
}

export default Talks
